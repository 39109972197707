<template>
  <!-- Remember my device dialog -->
  <AppModal
    v-model="model"
    :title="$t('ComponentOTPRememberMyDeviceModal.TitleModal').value"
    @hiding="$emit('hiding')"
  >
    <XeCardImageTitle
      :title="$t('ComponentOTPRememberMyDeviceModal.Title').value"
      :src="require('@galileo/assets/images/illustrations/recolorable/otp-simple.svg')"
      :alt="$t('ComponentOTPRememberMyDeviceModal.Title').value"
    >
      <p>
        {{ $t('ComponentOTPRememberMyDeviceModal.Description').value }}
      </p>
    </XeCardImageTitle>

    <template #footer>
      <AppModalFooter>
        <AppButton
          theme="secondary"
          analytics-name="remember-my-device-modal-no"
          @click="onFooterBtnClicked(false)"
        >
          {{ $t('ComponentOTPRememberMyDeviceModal.ButtonNo').value }}
        </AppButton>
        <AppButton analytics-name="remember-my-device-modal-yes" @click="onFooterBtnClicked(true)">
          {{ $t('ComponentOTPRememberMyDeviceModal.ButtonYes').value }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import XeCardImageTitle from '@galileo/components/XeCardImageTitle/XeCardImageTitle'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { useMediaQuery, useVModel, AppModal, AppModalFooter, AppButton } from '@oen.web.vue2/ui'
import { useAnalyticsStore, useI18nStore, useOtpStore } from '@galileo/stores'

export default {
  name: 'OTPRememberMyDeviceModal',
  emits: ['hide', 'hiding'],
  components: {
    AppModal,
    XeCardImageTitle,
    AppModalFooter,
    AppButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const otpStore = useOtpStore()
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()

    const { model } = useVModel(props, emit)

    const onFooterBtnClicked = (remember) => {
      otpStore.setRememberDevice(remember)
      analyticsStore.track({
        event: SEGMENT_EVENTS.REMEMBER_DEVICE_SELECTED,
        traits: {
          location: otpStore.getSegmentLocation,
          deviceRemembered: remember,
        },
      })
      model.value = false
    }

    return {
      model,
      mq: useMediaQuery(),
      onFooterBtnClicked,
      $t,
    }
  },
}
</script>
