import { ref, reactive } from '@vue/composition-api'


import { useRouter } from '@galileo/composables/useRouter'

import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'


import { useAlert } from '@oen.web.vue2/ui'
import { storeToRefs } from 'pinia'


import { useI18nStore, useProfileStore, useAuthStore, useOtpStore, useAppStore, useAnalyticsStore } from '@galileo/stores'

export default function (otpType) {
  const router = useRouter()
  const otpStore = useOtpStore()
  const profileStore = useProfileStore()
  const analyticsStore = useAnalyticsStore()
  const authStore = useAuthStore()
  const { add } = useAlert()
  const { $t, i18n } = useI18nStore()

  const initializeUser = ref(false)

  const logException = (text, exception) => {
    const appStore = useAppStore()
    appStore.logException(text, exception)
    appStore.messageBoxGenericError()
    initializeUser.value = false
  }

  const continueLogin = async (router, otpResult) => {
    const appStore = useAppStore()
    initializeUser.value = true
    try {
      if (otpResult && otpResult.profiles && otpResult.profiles.length > 1) {
        router.push({ name: 'ProfileSelection' })
      } else if (otpResult && !otpResult.hasProfile) {
        const msgBox = {
          title: $t('PageLogin.MissingProfileTitle').value,
          text: $t('PageLogin.MissingProfileText').value,
        }
        appStore.messageBoxGenericOk(msgBox.title, msgBox.text)
      } else {
        await authStore.initializeUser(i18n)
      }
    } catch (ex) {
      logException('Exception during initializing user', ex)
    }
  }
  const trackPhoneUpdateTraits = (isSuccessful) => {
    const profile = authStore.userProfile
    return {
      location: SEGMENT_LOCATIONS.ACCOUNT,
      phoneCountry: profile.homePhone?.countryCode,
      successfullyUpdated: isSuccessful,
    }
  }

  const continueEditPhone = async () => {
    const { flagProfilePhoneNumber, newPhoneNumber } = storeToRefs(profileStore)
    if (flagProfilePhoneNumber.value) {
      initializeUser.value = true

      try {
        const profile = authStore.userProfile
        profile.homePhone = newPhoneNumber.value
        await profileStore.updateUserProfile(profile)
        await profileStore.confirmResourceUpdate()
        analyticsStore.track({
          event: SEGMENT_EVENTS.PHONE_UPDATED,
          traits: trackPhoneUpdateTraits(true),
        })
        add($t('ComponentOTPRememberMyDeviceModal.AlertChanged').value)
      } catch (ex) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PHONE_UPDATED,
          traits: trackPhoneUpdateTraits(false),
        })
        logException('Exception during update user phone', ex)
      }
      await router.goBackTo({ name: 'AccountProfile' }, true)
    }
  }

  const continueToNextRoute = () => {
    const nextRoute = otpStore.getNextRoute
    otpStore.setNextRoute(null)
    if (nextRoute) {
      router.replace(nextRoute)
    } else {
      console.warn('OTP next route not set') // eslint-disable-line
    }
  }

  const exec = async (router, otpResult) => {
    // Copy otp state before resetting it
    const otpState = otpStore.$state
    otpStore.reset()

    switch (otpType) {
      case 'login':
        await continueLogin(router, otpResult)
        break
      case 'edit-phone':
        await continueEditPhone()
        break
      case 'forgot-password':
        continueForgotPassword(otpState)
        break
      default:
        continueToNextRoute()
        break
    }
  }

  return {
    initializeUser,
    exec,
  }
}
