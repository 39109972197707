import { ref, computed, reactive, watch } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { useAppStore } from '@galileo/stores'

export default function (params) {
  const closeDialog = ref(null)
  const otpType = computed(() => {
    return params.otpType
  })

  const router = useRouter()

  const setupCloseDialog = () => {
    closeDialog.value = null
    if (otpType.value === 'registration') {
      closeDialog.value = reactive({
        component: () => import('@galileo/components/Views/Register/RegisterLeaveDialog'),
        visible: false,
      })
    }
  }

  setupCloseDialog()
  watch(otpType, setupCloseDialog)

  const onCloseClicked = () => {
    const appStore = useAppStore()
    if (closeDialog.value) {
      closeDialog.value.visible = true
    } else if (otpType.value === 'forgot-password') {
      // Forget password should go to public-site/home
      appStore.navigateToXeApp()
    } else {
      router.go(-1)
    }
  }

  return {
    closeDialog,
    onCloseClicked,
  }
}
