import { reactive } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import { OTP_ACTIONS } from '@galileo/models/Cst/appVariables'

import { useOtpStore, useAuthStore } from '@galileo/stores'

export default function (otpType, deliveryType) {
  const otpStore = useOtpStore()

  const getResendOtpAction = () => {
    switch (otpType) {
      case 'login':
        if (otpStore.emailOtp) {
          return OTP_ACTIONS.PASS_SECURITY_QUESTIONS
        }
        return OTP_ACTIONS.LOGINTOKEN
      case 'forgot-password':
        return OTP_ACTIONS.RECOVERY_PASSWORD
      case 'edit-phone':
        return OTP_ACTIONS.VERIFYPHONE2
    }
  }

  if (otpType === 'registration') {
    return reactive(
      usePromiseLazy(() =>
        otpStore.requestCode({
          deliveryType: deliveryType.value,
        })
      )
    )
  }

  return reactive(
    usePromiseLazy(() => useAuthStore().resendOTP(deliveryType.value, getResendOtpAction()))
  )
}
