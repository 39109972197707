<template>
  <AppNavBar>
    <template #left>
      <slot name="left">
        <AppLink
          analytics-name="navbar-goto-xe"
          :href="appLink"
          aria-label="Xe Logo"
          class="navbar-logo-container"
          :class="{ branded: isBranded, 'money-transfer': useMoneyTransferLogo }"
          @click="onClick($event, $router)"
        >
          <XeLogo class="navbar-logo" />
        </AppLink>
      </slot>
    </template>
    <slot></slot>
    <template v-if="$slots.right" #right>
      <slot name="right"></slot>
    </template>
  </AppNavBar>
</template>

<script>
import { computed } from '@vue/composition-api'

import { AppNavBar, AppLink } from '@oen.web.vue2/ui'
import { useTheme } from '@galileo/composables/useTheme'
import XeLogo from '@galileo/components/XeLogo/XeLogo'

export default {
  name: 'XeNavbar',
  components: {
    AppNavBar,
    AppLink,
    XeLogo,
  },
  props: {
    logoHref: {
      type: String,
      default: '',
    },
    useMoneyTransferLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['logoClick'],
  setup(props, { emit }) {
    const { isBranded, brandedXeLogoSrc } = useTheme()
    const logoSrc = brandedXeLogoSrc(props.useMoneyTransferLogo)
    const appLink = computed(() => {
      const { logoHref } = props
      return logoHref?.trim().length > 0 ? logoHref : 'https://www.xe.com/'
    })

    const onClick = ($event, $router) => {
      emit('logoClick', {
        $event,
        $router,
      })
    }

    return {
      appLink,
      onClick,
      isBranded,
      logoSrc,
    }
  },
}
</script>
<style scoped>
.navbar-logo-container {
  @apply flex justify-center items-center h-14 w-full;
  @apply -ml-1_5;
  width: var(--theme-logo-max-width-mobile, 3.5rem);
  @apply cursor-pointer;
  min-width: 3.5rem;
  ::v-deep .link-text {
    @apply flex items-center;
  }
  .navbar-logo {
    margin-top: -2px;
    width: auto;
    min-width: 42px;
    max-height: var(--theme-logo-max-height-mobile, 3rem);
  }
  @screen sm {
    @apply h-16;
    width: var(--theme-logo-max-width, 4rem);
    .navbar-logo {
      min-width: 3rem;
      max-height: var(--theme-logo-max-height, 3rem);
    }
  }
}
.navbar-logo-container.branded {
  width: calc(var(--theme-logo-max-width-mobile, 3.5rem));
  @screen sm {
    width: calc(var(--theme-logo-max-width, 4rem) + 4.5rem);
  }
  /* // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
  /*margin-top: -12px;*/
}
.navbar-logo-container.branded.money-transfer {
  width: calc(var(--theme-logo-max-width-mobile, 3.5rem) + 6rem);
  @screen sm {
    width: calc(var(--theme-logo-max-width, 4rem) + 6.5rem);
  }
}
.navbar-logo-text {
  position: absolute;
  left: 22px;
  font-size: 13px;
  font-weight: 600;
  margin-top: -16px;
}

::v-deep .step {
  &.step--active {
    color: var(--theme-step-active-color, var(--theme-text-primary, #333)) !important;
    /* The round active dot*/
    &::after {
      background-color: var(
        --theme-step-active-after-bg-color1,
        var(--theme-color-primary, rgba(0, 108, 224, 1))
      );
    }
  }
}
</style>
