import { computed, reactive, ref } from '@vue/composition-api'

export function useAppAlertHelpers() {
  const message = ref('')
  const currentTheme = ref('')

  const setMessage = (msg = null, theme = 'red') => {
    message.value = msg
    currentTheme.value = theme
  }

  return reactive({
    message: computed(() => message.value),
    theme: computed(() => currentTheme.value),
    setMessage,
  })
}
