<template>
  <div class="card-image-title">
    <AppIllustration :src="src" class="mb-6" :alt="alt" :image-is-colorable="colorable" />
    <h2
      v-if="title"
      :class="{ 'card-image-title-title': true, 'card-image-title-margin': !subTitle }"
    >
      {{ title }}
    </h2>
    <h2 v-if="subTitle" class="card-image-title-title card-image-title-margin">{{ subTitle }}</h2>
    <!-- Default slot -->
    <div class="card-image-title-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeMount, onUnmounted } from '@vue/composition-api'
import AppIllustration from '@galileo/components/AppIllustration/AppIllustration'
export default {
  components: {
    AppIllustration,
  },
  name: 'XeCardImageTitle',
  emits: [],
  props: {
    src: {
      type: [String, Object],
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    colorable: {
      type: Boolean,
      default: true,
    },
    subTitle: {
      type: String,
      default: null,
    },
  },
  setup() {
    const hasImageTitle = inject('hasImageTitle')
    onBeforeMount(() => {
      hasImageTitle.value = true
    })
    onUnmounted(() => {
      hasImageTitle.value = false
    })
  },
}
</script>

<style scoped>
.card-image-title {
  @apply mb-6;
  .card-image-title-image {
    @apply ml-auto mr-auto;
    @apply mb-6;
  }

  .card-image-title-title {
    /* TODO figma designs have both h1 and h2 */
    @apply type-h2 text-center text-primary-text;
  }

  .card-image-title-margin {
    @apply mb-4;
    @screen sm {
      /* TODO figma designs have both mb-4 and mb-2 see #308 */
      @apply mb-2;
    }
  }

  .card-image-title-content {
    @apply type-subtitle text-center text-secondary-text;
  }
}
</style>
