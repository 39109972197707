<template>
  <img :src="logoSrc" alt="Xe Logo" />
</template>

<script>
import { useTheme } from '@galileo/composables/useTheme'

export default {
  name: 'XeLogo',
  setup() {
    const { logoSrc } = useTheme()
    return {
      logoSrc,
    }
  },
}
</script>
