<template>
  <div class="relative">
    <div
      class="max-w-full"
      :class="{ 'mx-auto': themeImageId !== null }"
      :style="[themeImageId ? { content: `var(--${themeImageId})` } : null]"
    >
      <svg
        v-if="imageIsColorable"
        class="mx-auto absolute top-0 left-0 right-0 w-full h-full text-orange"
        :style="{ color: imageColor }"
        :alt="alt"
      >
        <use width="100%" height="100%" :href="`${src}#${imageId}`"></use></svg
      ><img :class="{ invisible: imageIsColorable }" :src="src" :alt="alt" class="mx-auto" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppIllustration',
  emits: [],
  props: {
    src: {
      type: [String, Object],
      required: true,
    },
    imageId: {
      type: String,
      required: false,
      default: 'image',
    },
    themeImageId: {
      type: String,
      required: false,
    },
    imageIsColorable: {
      type: Boolean,
      required: false,
      default: false,
    },
    imageColor: {
      type: String,
      required: false,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  setup() {},
}
</script>
<style scoped></style>
