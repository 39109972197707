import { reactive } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'


import { useOtpStore, useAuthStore } from '@galileo/stores'

export default function (type, code) {
  const otpStore = useOtpStore()

  if (type === 'login') {
    return reactive(usePromiseLazy(() => useAuthStore().verifyOTP(code.value)))
  }
  // return reactive(usePromiseLazy(() => store.dispatch('OTP/validateCode', code.value)))
}
