import { ref, computed } from '@vue/composition-api'

export default function () {
  const requestCount = ref(0)
  const failedCount = ref(0)

  const incrementRequestCount = () => {
    requestCount.value++
  }

  const incrementFailedCount = () => {
    failedCount.value++
  }

  const resetFailedCounter = () => {
    failedCount.value = 0
  }

  const resetRequestCounter = () => {
    requestCount.value = 0
  }

  return {
    requestCount: computed(() => requestCount.value),
    failedCount: computed(() => failedCount.value),
    canRequestNewCode: computed(() => {
      return (requestCount.value + 1) % 3 !== 0
    }),
    lockedOut: computed(() => {
      const { value } = failedCount
      return value > 0 && value % 3 === 0
    }),
    incrementRequestCount,
    incrementFailedCount,
    resetFailedCounter,
    resetRequestCounter,
  }
}
