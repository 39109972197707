<template>
  <div class="layout-page" :class="classes">
    <XeNavBar :logo-href="logoHref" @logoClick="$emit('logoClick', $event)">
      <template v-if="mq.current === 'xs'" #left>
        <slot name="navLeft" />
      </template>
      <template v-if="mq.current === 'xs'">
        <slot name="title" />
      </template>
      <template #right>
        <slot name="navRight">
          <!--TODO go to Ria Home Page-->
          <AppBackButton
            name="Go Back"
            :analytics-name="analyticsName ? `${analyticsName}-back` : null"
            @click="$router.go(-1)"
          />
        </slot>
      </template>
    </XeNavBar>
    <AppCard>
      <template v-if="$slots.title" #header>
        <AppCardHeader>
          <template v-if="mq.sm === true" #left>
            <slot name="navLeft" />
          </template>
          <slot name="title"></slot>
        </AppCardHeader>
      </template>
      <slot name="content"></slot>
      <template v-if="$slots.footer" #footer>
        <slot name="footer" />
      </template>
    </AppCard>
    <!-- @slot default: slot used for modals-->
    <slot />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import XeNavBar from '@galileo/components/XeNavBar/XeNavBar'
import { useEnvStore } from '@galileo/stores'

import { AppCard, AppCardHeader, AppBackButton, useMediaQuery } from '@oen.web.vue2/ui'

export default {
  name: 'LayoutPage',
  components: {
    AppCardHeader,
    AppCard,
    XeNavBar,
    AppBackButton,
  },
  emits: ['logoClick'],
  props: {
    analyticsName: {
      type: String,
      default: 'layout-page',
      required: () => {
        const envStore = useEnvStore()
        return !!envStore.env.VUE_APP_ANALYTICS_ENABLED
      },
    },
    logoHref: {
      type: String,
      default: '',
    },
  },
  setup() {
    const mq = useMediaQuery()
    const classes = computed(() => ({
      'layout-page--xs': mq.current === 'xs',
    }))
    return { mq, classes }
  },
}
</script>

<style scoped>
.layout-page {
  @apply w-full h-full;

  &.layout-page--xs {
    @apply flex flex-col;

    > .card {
      @apply flex-1;
      ::v-deep .card-header {
        @apply hidden;
      }
    }
    .nav-bar {
      @apply sticky top-0;
    }
  }
  > .card {
    @screen sm {
      @apply mb-16;
    }
  }
}
</style>
