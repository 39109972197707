<template>
  <LayoutPage
    class="otp"
    :logo-href="getLogoHref"
    :analytics-name="getAnalyticsName"
    @logoClick="onLogoClick"
  >
    <template #title>
      <span>{{ isEmailOtp ? $t('PageOTP.TitleEmail').value : $t('PageOTP.Title').value }}</span>
    </template>
    <template #navLeft>
      <AppBackButton
        name="Go Back"
        icon="<"
        :analytics-name="`${getAnalyticsName}-back`"
        @click="$router.go(-1)"
      />
    </template>
    <template #navRight>
      <AppBackButton
        name="Go Back"
        :analytics-name="`${getAnalyticsName}-back`"
        icon="x"
        @click="onCloseClicked"
      />
    </template>
    <template #content>
      <!-- Card content -->
      <p class="type-subtitle mb-6">
        {{ $t('PageOTP.Description').value }}<br />
        <strong v-if="maskedPhone">{{ maskedPhone }}</strong>
        <strong v-if="maskedEmail">{{ maskedEmail }}</strong>
      </p>

      <Transition name="fade">
        <AppAlert v-if="appAlert.message" class="mb-6" :theme="appAlert.theme">
          {{ appAlert.message }}
        </AppAlert>
      </Transition>

      <!-- Form -->
      <form id="OTPForm" @submit.prevent="submit">
        <AppInputOTP
          ref="otpInputRef"
          v-model="code"
          analytics-name="otp-input"
          :validation="validation.code"
          :label="$t('PageOTP.LabelSecurityCode').value"
          :disabled="lockedOut"
        />
      </form>
    </template>
    <template #footer>
      <!--Submit-->
      <AppCardFooter>
        <AppButton
          v-if="isEmailOtp"
          type="button"
          theme="secondary"
          analytics-name="otp-submit"
          :disabled="lockedOut || inCooldown"
          @click="requestOTPCode"
        >
          {{ $t('ComponentOTPHelpDialog.ButtonResend').value }}
        </AppButton>

        <AppButton
          v-else
          type="button"
          theme="secondary"
          analytics-name="otp-submit"
          :disabled="lockedOut || inCooldown"
          @click="modalState = 'help'"
        >
          {{ $t('ComponentOTPHelpDialog.ButtonHelp').value }}
        </AppButton>

        <AppButton
          analytics-name="otp-done"
          :disabled="submitBtnDisabled"
          type="submit"
          :loading="otpValidateCode.loading || initializeUser"
          form="OTPForm"
        >
          {{ $t('PageOTP.ButtonDone').value }}
        </AppButton>
      </AppCardFooter>
    </template>

    <OTPHelpDialog
      :value="modalState === 'help'"
      :request-count="requestCount"
      :email-otp="isEmailOtp"
      @maskClick="modalState = ''"
      @requestSelected="onHelpRequestSelected"
      @updateErrorMsg="appAlert.setMessage"
      @inCooldown="inCooldown = $event"
    />

    <OTPRememberMyDeviceModal
      :value="modalState === 'remember-device'"
      @input="modalState = ''"
      @hiding="onOtpComplete($router, otpResult)"
    />

    <OTPResendErrorDialog
      :is-email="deliveryType === 'Email'"
      :value="modalState === 'resend-error'"
      @input="modalState = ''"
    />

    <!-- Close dialog component -->
    <Component :is="closeDialog.component" v-if="closeDialog" v-model="closeDialog.visible" />
  </LayoutPage>
</template>

<script>
import { ref, computed, watch, onMounted, nextTick } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { integer } from '@vuelidate/validators'
import { useRouter } from '@galileo/composables/useRouter'
import {
  useOtpCounter,
  useOtpValidateApi,
  useOtpRequestCodeApi,
  useOtpOnComplete,
  useOtpCloseDialog,
} from '@galileo/composables/useOtp'

import OTPHelpDialog from '@galileo/components/Views/OTP/OTPHelpDialog'
import OTPRememberMyDeviceModal from '@galileo/components/Views/OTP/OTPRememberMyDeviceModal'
import OTPResendErrorDialog from '@galileo/components/Views/OTP/OTPResendErrorDialog'
import LayoutPage from '@galileo/components/LayoutPage/LayoutPage'
import { otpDeliveryTypes } from '@galileo/api/ct/OTP/resource/_phoneNumber/post'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { useAppAlertHelpers } from '@galileo/composables/useAppAlertHelpers'

import {
  useMediaQuery,
  AppCardFooter,
  AppButton,
  AppInputOTP,
  AppAlert,
  AppBackButton,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useAuthStore,
  useOtpStore,
  useAnalyticsStore,
  storeToRefs,
} from '@galileo/stores'

export default {
  name: 'OTP',
  components: {
    LayoutPage,
    AppCardFooter,
    AppButton,
    AppInputOTP,
    AppAlert,
    OTPHelpDialog,
    OTPRememberMyDeviceModal,
    AppBackButton,
    OTPResendErrorDialog,
  },
  setup() {
    const otpStore = useOtpStore()
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const authStore = useAuthStore()

    // Set otp input ref https://vuedose.tips/access-template-refs-in-composition-api-in-vuejs-3/
    const otpInputRef = ref(null)
    onMounted(() => {
      // Reset remember device
      otpStore.setRememberDevice(false)
      // Focus otp input
      otpInputRef.value.focus()
    })

    // Form & validation
    const code = ref(otpStore.getCode)
    const isEmailOtp = ref(otpStore.isEmailOtp)
    const deliveryType = ref(isEmailOtp.value ? otpDeliveryTypes.Email : otpDeliveryTypes.SMS)

    const validation = useValidation({
      code: {
        $value: code,
        integer: {
          $validator: integer.$validator,
          $message: $t('PageOTP.ValidationCode').value,
        },
      },
    })

    // Snack alert
    const appAlert = useAppAlertHelpers()
    // Hide snack alert when OTP code input updated
    watch(code, (v) => {
      if (v.length > 0) {
        if (appAlert.message && otpCounter.canRequestNewCode) {
          appAlert.setMessage()
        }
        if (otpValidateCode.error) {
          otpValidateCode.error = false
        }
      }
    })

    const { otpType } = router.currentRoute.params

    const otpValidateCode = useOtpValidateApi(otpType, code)
    const otpRequestCode = useOtpRequestCodeApi(otpType, deliveryType)
    const otpCounter = useOtpCounter()

    otpStore.setOtpType(otpType)

    const resendSuccessText = computed(() => {
      switch (deliveryType.value) {
        case otpDeliveryTypes.Voice:
          return $t('PageOTP.ResendVoiceText').value
        case otpDeliveryTypes.Email:
          return $t('PageOTP.ResendEmailText').value
        default:
          return $t('PageOTP.ResendText').value
      }
    })

    const requestOTPCode = async () => {
      await otpRequestCode.exec()
      if (!otpRequestCode.error) {
        // Update snack message
        const { canRequestNewCode } = otpCounter
        if (canRequestNewCode.value) {
          appAlert.setMessage(resendSuccessText.value, 'green')
        }
        // Get code from store
        if (otpStore.getCode) {
          code.value = otpStore.getCode
        }

        // Once a user enters email OTP, then cannot use other delivery types
        // Using others delivery types causes OTP resend APIs to error
        if (deliveryType.value === otpDeliveryTypes.Email) {
          isEmailOtp.value = true
        }

        otpCounter.incrementRequestCount()
      } else {
        modalState.value = 'resend-error'
      }
    }

    const getSegmentMethodName = (action) => {
      switch (action) {
        case 'SMS':
          return 'Resend Code'
        case 'Voice':
          return 'Call Me Instead'
        case 'Email':
          return 'I can’t access this phone'
      }
    }
    // Help dialog
    const onHelpRequestSelected = async ({ requestType, action }) => {
      if (requestType === 'request-code') {
        modalState.value = ''
        // Set OTP delivery type
        deliveryType.value = action
        //trigger segment event
        analyticsStore.track({
          event: SEGMENT_EVENTS.OTP_HELP_SELECTED,
          traits: {
            location: otpStore.getSegmentLocation,
            method: getSegmentMethodName(action),
          },
        })

        // Request new OTP code
        await requestOTPCode()
      }
    }

    watch(otpCounter.lockedOut, (v) => {
      if (v) {
        appAlert.setMessage($t('PageOTP.WaitText').value)
      }
    })

    const otpResult = ref(null)
    // Submit form, validate code & show remember my device
    const submit = async () => {
      if (submitBtnDisabled.value) {
        return
      }

      // Validate security code
      await otpValidateCode.exec()
      const { result, error } = otpValidateCode
      if (error) {
        // Handle unauthorized validation request
        if (error.code === 401) {
          router.replace({
            name: 'AccountLockedTooManyAttempts',
            params: {
              redirect: otpType,
            },
          })
        } else {
          otpCounter.incrementFailedCount()
          // Clear code value
          code.value = ''
          // Update snack message
          appAlert.setMessage($t('PageOTP.WrongCodeText').value)

          if (otpCounter.lockedOut.value) {
            // Set locked out timer to 15 minutes
            setTimeout(otpCounter.resetFailedCounter, 15 * 60 * 1000)
          }

          // Focus otp input on next tick
          // Ensures otpInputRef value is updated before focusing
          nextTick(() => otpInputRef.value.focus())
        }
      } else {
        otpResult.value = result
        modalState.value = 'remember-device'
      }
    }

    const submitBtnDisabled = computed(() => {
      return (
        validation.$anyInvalid ||
        code.value.length !== 6 ||
        !!otpValidateCode.error ||
        otpCounter.lockedOut.value
      )
    })

    const { initializeUser, exec: onOtpComplete } = useOtpOnComplete(otpType)

    const modalState = ref('')
    const inCooldown = ref(false)

    const { isAuthenticated } = storeToRefs(authStore)

    const getLogoHref = computed(() => {
      if (isAuthenticated.value) {
        return '/activity'
      }
      return ''
    })

    const onLogoClick = ({ $event }) => {
      if (isAuthenticated.value) {
        $event.preventDefault()
        router.replace({
          name: 'Activity',
        })
      }
      otpStore.reset()
    }

    const getAnalyticsName = computed(() => {
      let name = router.currentRoute.path.substring(1)
      name = name.replace(/\//g, '-')
      name = name.replace(/--/g, '-')
      return name
    })

    const { closeDialog, onCloseClicked } = useOtpCloseDialog(router.currentRoute.params)
    return {
      mq: useMediaQuery(),
      maskedPhone: otpStore.getMaskedPhone,
      maskedEmail: otpStore.getMaskedEmail,
      appAlert,
      code,
      otpInputRef,
      validation,
      submitBtnDisabled,
      lockedOut: otpCounter.lockedOut,
      requestCount: otpCounter.requestCount,
      submit,
      otpValidateCode,
      onHelpRequestSelected,
      initializeUser,
      onOtpComplete,
      modalState,
      inCooldown,
      $t,
      getAnalyticsName,
      getLogoHref,
      onLogoClick,
      closeDialog,
      onCloseClicked,
      isEmailOtp,
      requestOTPCode,
      deliveryType,
      otpResult,
    }
  },
}
</script>

<style scoped></style>
