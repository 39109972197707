<template>
  <AppDialog v-model="model">
    <template #header>
      <AppDialogHeader>{{
        isEmail
          ? $t('ComponentOTPResendErrorDialog.HeaderEmail').value
          : $t('ComponentOTPResendErrorDialog.Header').value
      }}</AppDialogHeader>
    </template>

    <!-- Content -->
    <p>
      {{
        isEmail
          ? $t('ComponentOTPResendErrorDialog.ContentEmail').value
          : $t('ComponentOTPResendErrorDialog.Content').value
      }}
    </p>

    <template #footer>
      <AppDialogFooter>
        <AppButton
          analytics-name="help-dialog-default"
          type="button"
          @click="$emit('input', false)"
        >
          {{ $t('ComponentOTPResendErrorDialog.ButtonOk').value }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import { useVModel, AppDialog, AppDialogHeader, AppDialogFooter, AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'OTPResendErrorDialog',
  components: {
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppButton,
  },
  emits: [],
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    isEmail: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()

    return {
      model,
      $t,
    }
  },
}
</script>

<style scoped></style>
