<template>
  <AppDialog v-model="visible" dismissible>
    <template #header>
      <AppDialogHeader>{{ $t('ComponentOTPHelpDialog.Header').value }}</AppDialogHeader>
    </template>

    <!-- Content -->
    <p>
      {{ $t('ComponentOTPHelpDialog.Title').value }}
    </p>

    <template #footer>
      <AppDialogFooter>
        <AppButton
          analytics-name="help-dialog-default"
          type="button"
          theme="text"
          @click="onResendCodeOptionClickedDefault()"
        >
          {{ $t('ComponentOTPHelpDialog.ButtonResend').value }}
        </AppButton>
        <AppButton
          v-if="!emailOtp"
          analytics-name="help-dialog-voice"
          type="button"
          theme="text"
          @click="onResendCodeOptionClicked('Voice')"
        >
          {{ $t('ComponentOTPHelpDialog.ButtonCall').value }}
        </AppButton>
        <AppButton
          v-if="!emailOtp && canEditNumber"
          analytics-name="help-dialog-edit-number"
          type="button"
          theme="text"
          @click="onResendCodeOptionClicked('Email')"
        >
          {{ $t('ComponentOTPHelpDialog.ButtonEditNumber').value }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useI18nStore } from '@galileo/stores'

import { otpDeliveryTypes } from '@galileo/api/ct/OTP/resource/_phoneNumber/post'
import { useRouter } from '@galileo/composables/useRouter'

import {
  useMediaQuery,
  AppButton,
  AppDialog,
  AppDialogHeader,
  AppDialogFooter,
} from '@oen.web.vue2/ui'

export default {
  name: 'OTPHelpDialog',
  components: {
    AppButton,
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
  },
  emits: ['requestSelected', 'updateErrorMsg'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    requestCount: {
      type: Number,
      default: 0,
      required: true,
    },
    emailOtp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const router = useRouter()

    const visible = computed({
      get: () => props.value,
      set: () => emit('maskClick'),
    })

    const disableHelpBtn = () => {
      // Set default cool down time
      let cooldownTime = 15
      // Get current request count from props
      const { requestCount } = props
      // Calculate cool down time
      if ((requestCount + 1) % 3 === 0) {
        const cooldownMinutes = Math.pow(requestCount + 1, 2) / 3
        emit(
          'updateErrorMsg',
          `${$t('ComponentOTPHelpDialog.WaitPreText').value} ${cooldownMinutes} ${
            $t('ComponentOTPHelpDialog.WaitPostText').value
          }`
        )
        cooldownTime = 60 * cooldownMinutes
      }

      // Set cool down timer
      setTimeout(() => {
        emit('inCooldown', false)
      }, 1000 * cooldownTime)
      emit('inCooldown', true)
    }

    const onResendCodeOptionClicked = (deliveryType) => {
      disableHelpBtn()
      onRequestOptionClicked('request-code', deliveryType)
    }
    const onResendCodeOptionClickedDefault = () => {
      let deliveryType = otpDeliveryTypes.SMS
      if (props.emailOtp) {
        deliveryType = otpDeliveryTypes.Email
      }
      onResendCodeOptionClicked(deliveryType)
    }

    const onRequestOptionClicked = (requestType, action) => {
      emit('requestSelected', {
        requestType,
        action,
      })
    }
    const cannotEditNumberOtpTypes = ['registration', 'edit-phone']
    const canEditNumber = computed(() => {
      return !cannotEditNumberOtpTypes.includes(router.currentRoute.params.otpType)
    })

    return {
      mq: useMediaQuery(),
      visible,
      onResendCodeOptionClicked,
      onResendCodeOptionClickedDefault,
      canEditNumber,
      $t,
    }
  },
}
</script>

<style scoped></style>
